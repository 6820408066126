<template>
  <div class="news" id="lshNews">
    <div class="lsh-news">
      <p class="lsh-title">
        新闻动态
      </p>
      <div class="lsh-news-main clearfix">
        <div class="lsh-news-left">
          <a href="javascript:void(0);" class="news-list clearfix">
            <p class="news-image">
              <img v-lazy="images.url1" alt="" class="hvr-scale">
            </p>
            <div class="news-mess">
              <div class="news-mess-title clearfix">
                <div class="news-title ellipsis" title="">苏智协会长施正华一行调研蓝珊瑚<br>详细听取了公司产业规划与发展方向</div>
                <div class="news-time">2022 - 06 - 11</div>
              </div>
              <div class="news-mess-con ellipsis-2l" title=""></div>
            </div>
          </a>
          <a href="javascript:void(0);" class="news-list clearfix">
            <p class="news-image">
              <img v-lazy="images.url2" alt="" class="hvr-scale">
            </p>
            <div class="news-mess">
              <div class="news-mess-title clearfix">
                <div class="news-title ellipsis" title="">蓝珊瑚员工集体团建 <br> 熔炼团队，挥洒激情</div>
                <div class="news-time">2022 - 05 - 08</div>
              </div>
              <div class="news-mess-con ellipsis-2l" title=""></div>
            </div>
          </a>
          <a href="javascript:void(0);" class="news-list clearfix">
            <p class="news-image">
              <img v-lazy="images.url3" alt="" class="hvr-scale">
            </p>
            <div class="news-mess">
              <div class="news-mess-title clearfix">
                <div class="news-title ellipsis" title="">员工协作研发口罩机<br>发挥职能装备优势<br>助力全球抗疫</div>
                <div class="news-time">2022 - 03 - 24</div>
              </div>
              <div class="news-mess-con ellipsis-2l" title=""></div>
            </div>
          </a>
        </div>
        <div class="lsh-news-right">
          <p class="news-lg-img">
            <img v-lazy="images.url4" alt="" style="width:100%;height:400px">
          </p>
        </div>
      </div>
      <p class="lsh-sub-title">关注蓝珊瑚，及时了解行业资讯</p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      images:{
        url1:require('@/assets/img/news.png'),
        url2:require('@/assets/img/new_left2.jpeg'),
        url3:require('@/assets/img/new_left3.jpeg'),
        url4:require('@/assets/img/newright.jpg')
      }
    }
  }
}
</script>

<style lang='less'>
.news{
  .lsh-news{
    width: 1300px;
    margin: 0 auto;
    padding: 10px;
    .lsh-title{
        font-size: 48px;
        text-align: center;
        color: #333;
        line-height: 48px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .lsh-sub-title{
      margin: 20px 0;
      font-size: 14px;
      text-align: center;
      color: #000;
      font-weight: lighter;
    }
    .lsh-news-main{
      background-color: #f0f0f0;
      margin-top: 20px;
      padding: 15px;
      .lsh-news-left{
        float: left;
        width: 50%;
        .news-list{
          display: flex;
          .news-image{
            float: left;
            overflow: hidden;
            .hvr-scale {
              width: 180px;
              height: 120px;
              transition: all .3s;
            }
          }
          .news-mess{
            flex: 1;
            margin-left: 15px;
            border-bottom: 1px solid #ccc;
            padding: 10px;
            .news-mess-title{
              .news-title{
                float: left;
                width: 295px;
                font-size: 16px;
                line-height: 16px;
                color: #333;
                font-weight: lighter;
                text-align: left;
              }
              .news-time{
                float: right;
                font-size: 14px;
                line-height: 16px;
                color: #666;
                font-weight: lighter;
              }
            }
            .clearfix{
              *zoom: 1;
            }
            .clearfix:after{
              content: "";
              display: block;
              height: 0;
              clear:both;
              visibility: hidden;
            }
            .news-mess-con {
              margin-top: 15px;
              padding-right: 20px;
              font-size: 14px;
              line-height: 24px;
              color: #666;
              font-weight: lighter;
              position: relative;
            }
            .news-mess-con:after {
              content: "";
              position: absolute;
              right: 0;
              bottom: 8px;
              width: 15px;
              height: 10px;
              background: url(../assets/img/icon-index.png) -52px -12px no-repeat;
            }
            .ellipsis-2l {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
        .news-list + .news-list {
          margin-top: 29px;
        }
      }
      .lsh-news-right{
        display: block;
        float: right;
        width: 50%;
        // padding-left: 30px;
        position: relative;
        overflow: hidden;
        .news-lg-img{
          width: 100%;
          padding: 15px;
          img{
            // border: 0;
          }
        }
      }
    }
    .more{
      display: block;
      width: 120px;
      height: 40px;
      margin: 65px auto 0;
      border: 2px solid #ccc;
      text-align: center;
      font-size: 14px;
      line-height: 36px;
      color: #000;
      font-weight: lighter;
    }
    .clearfix{
      *zoom: 1;
    }
    .clearfix:after{
      content: "";
      display: block;
      height: 0;
      clear:both;
      visibility: hidden;
    }
  }
}
</style>
